import playBtn from "../assets/images/play-icon.svg";
import software from "../styles/section__software/software.module.scss";

const Software = () => (
  <article id="software-section" className={software.section}>
    <div className={`container ${software.content}`}>
      <div>
        <h1>COMMUNITY SHARES</h1>
        <p>
          We vet software, technology, health care, and hospitality businesses
          that seek investments between $50k to $5M in early-stage capital. We
          use a provision in the 2012 JOBS Act which allows unaccredited
          investors to purchase equity alongside accredited investor in
          early-stage companies.
        </p>
        <p>
          We also vet opportunities for Businesses in those spaces to do
          Community Exists. In a Community Exit, the ownership would transition
          from investor ownership to ownership by the people who rely on it
          most. Those people might be users, workers, customers, participant
          organizations, or a combination of such stakeholder groups. The
          mechanism for co-ownership might be a cooperative, a trust, or even
          crypto-tokens. The community might own the whole company when the
          process is over or just a substantial enough part of it to make a
          difference.
        </p>
      </div>
    </div>
  </article>
);

export default Software;
