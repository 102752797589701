const Nav = (i) => {
  // <nav>
  //   <div>
  //     <a href="#about-section">About the project</a>
  //     <a href="#capsule-section">Digital Capsule</a>
  //     <a href="#gb-ventures-section">Green book Ventures</a>
  //     <a href="#opportunity-zone-section">Opportunity Zones</a>
  //     <a href="#cooperative-section">Art & Finance Cooperative</a>
  //     <a href="#capsule-section">Platform Cooperative</a>
  //     <a href="#capsule-section">Case study</a>
  //     <a href="#capsule-section">Partnership</a>
  //     <a href="#capsule-section">Activations</a>
  //   </div>
  // </nav>
  const sections = [
    "About Green Book",
    "Digital Capsule",
    "Investment Vehicles",
    "Opportunity Zones",
    "Art Collective",
    "Community Shares",
    "Fund to Funds",
    "Case study",
    "Activations",
  ];
  return sections[i];
};

export default Nav;
