import { useState, useEffect } from "react";
import capsule from "../styles/section__capsule/capsule.module.scss";
import imagePast from "../assets/images/past.jpg";
import imagePresent from "../assets/images/present.jpg";
import imageFuture from "../assets/images/future.jpg";

const data = [
  {
    title: "PAST",
    subTitle: "Chronicle the PAST",
    image: imagePast,
    content: `
    Reveal and honor a significant Black experience and contribution
     to American History — By Digitally Memorializing 20th Century
      Green Book Travel Guide.  We will aggregated the richness of
       the 20th century into an experience people can immersive
        themselves in to understand a pivotal point in American History.`,
  },
  {
    title: "PRESENT",
    subTitle: "CONNECT IN THE PRESENT",
    image: imagePresent,
    label: "Chronicle the PAST",
    content: `
    The time is now! Each day is an opportunity co-create wealth.
    That is we provide framework and applications for people in 
    communities to do just that.  We empower communities with 
    activities, and pathways to get involved, create value, 
    receive recognition and steward economic success and 
    empowerment in communities.`,
  },
  {
    title: "FUTURE",
    subTitle: "COOPERATE TO BUILD THE FUTURE",
    image: imageFuture,
    content: `
    We have a vision and asset ownership is a the key its manifestation.
    Provide opportunities for investment empowers more Americans the
    opportunity to participate in capitalism.`,
  },
];

const Thumb = ({ current, setSection, setCurrent, data }) => (
  <div className={`col-md-3 ${capsule.thumbWrapper}`}>
    <a
      href="#0"
      onClick={() => {
        setCurrent(data.title);
        setSection(data);
        window.scrollTo(0, document.body.scrollHeight);
      }}
      className={`d-flex align-items-center justify-content-center ${
        capsule.thumb
      } ${current === data.title ? capsule.active : ""}`}
    >
      <img src={data.image} alt="" />
      <h4 className="d-flex align-items-center justify-content-center">
        {data.subTitle}
      </h4>
    </a>
  </div>
);

const DigitalCapsule = ({ toggleMenu, menuState }) => {
  const [current, setCurrent] = useState("past");
  const [section, setSection] = useState({
    title: "PAST",
    subTitle: "CHRONICLE THE PAST",
    content: `
    Reveal and honor a significant Black experience and contribution
     to American History — By Digitally Memorializing 20th Century
      Green Book Travel Guide.  We will aggregated the richness of
       the 20th century into an experience people can immersive
        themselves in to understand a pivotal point in American History.`,
  });

  return (
    <article id="capsule-section" className={capsule.section}>
      <div className="container" style={{ height: "100%" }}>
        <div className={capsule.title}>
          <div className="row">
            <div className="col-md-10">
              <h2>DIGITAL CAPSULE</h2>
              {/* <h4>{section?.title}</h4> */}
              <p>
                As a part of the mission we seek to launch an Interactive
                Digital destination co-owned by Green Book members for all to
                learn, take action and invest to restore and resurge cooperative
                economics in stagnate and distressed American communities.
              </p>
            </div>
          </div>
        </div>
        <div
          className={`d-flex align-items-center justify-content-start ${capsule.thumbWrapper}`}
        >
          {data.map((record) => (
            <Thumb
              key={record.title}
              data={record}
              curren={current}
              setCurrent={setCurrent}
              setSection={setSection}
            />
          ))}
        </div>
        <div className="row">
          <div className="col-md-10">
            <h5 className={capsule.subContentTitle}>{section?.subTitle}</h5>
            <p className={capsule.subContent}>{section?.content}</p>
          </div>
        </div>
      </div>
    </article>
  );
};

export default DigitalCapsule;
