import playBtn from "../assets/images/play-icon.svg";
import fundToFund from "../styles/section__fundToFund/fundToFund.module.scss";
import image from "../assets/images/fund-to-fund.jpg";

const FundToFund = () => (
  <article id="fundToFund-section" className={fundToFund.section}>
    <div className={`container ${fundToFund.content}`}>
      <div className="row">
        <div className={`col-md-6 ${fundToFund.container}`}>
          <img className={fundToFund.image} src={image} alt="" />
        </div>
        <div className={`col-md-6 ${fundToFund.container}`}>
          <div>
            <h1>Fund to Funds</h1>
            <p>
              Our Green Book Fund of Funds (FOF) investment vehicle is a
              portfolio composed of shares of other funds rather than investing
              directly in securities. Our strategy to invest in a fund of funds
              aims to achieve broader diversification and asset allocation where
              investors can get broader exposure with reduced risks than
              investing directly in securities or alongside venture firms. We
              seek partnerships with top portfolio managers and provide a
              pathway of the best selection of funds to invest in based on their
              performance and profitability.
            </p>
          </div>
        </div>
      </div>
    </div>
  </article>
);

export default FundToFund;
