import playBtn from "../assets/images/play-icon.svg";
import about from "../styles/section__about/about.module.scss";
import menuIcon from "../assets/icons/menu.svg";

const About = () => (
  <article id="about-section" className={about.section}>
    <div className={`container ${about.content}`}>
      <div className="row">
        <div className={about.wrapper}>
          {/* <a className="toggle-btn" href="#0">
            <img src={menuIcon} alt="Menu-icon" />
          </a> */}
          <h1>GREENBOOK</h1>
          <h2>COOPERATIVE VENTURES</h2>
          <h3>How to Co-Create Wealth in America.</h3>
          {/* <p>Owned By Co-Create Media A Human Elevation Company</p> */}

          <div className={about.videoWrapper}>
            <div className={about.documentaryCTA}>
              <a href="#0">
                <img src={playBtn} alt="" />
                <div>Green Book History</div>
              </a>
              <p> 15 minute video</p>
            </div>
            <div className={about.documentaryCTA}>
              <a href="#0">
                <img src={playBtn} alt="" />
                <div> Watch Green Book</div>
              </a>
              <p>Partner Presentation</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </article>
);

export default About;
