import playBtn from "../assets/images/play-icon.svg";
import art from "../styles/section__art/art.module.scss";

const Art = () => (
  <article id="art-section" className={art.section}>
    <div className={art.content}>
      <h1 className={art.title}>The Art Collective</h1>
      <p>
        We help Contemporary artists of the American Diaspora become more
        visible and valued by designing a cooperative investment vehicle and
        community support program that simultaneously provides credit lines for
        businesses, beautifies communities, creates employment opportunities,
        and grows asset value.
      </p>
      <a href="#0" className={`btn btn-light rounded-pill ${art.button}`}>
        Download Whitepaper
      </a>
    </div>
  </article>
);

export default Art;
