import About from "./views/About";
import DigitalCapsule from "./views/DigitalCapsule";
import InvestmentVehicle from "./views/InvestmentVehicle";
import OpportunityZone from "./views/OpportunityZone";
import Art from "./views/Art";
import Software from "./views/Software";
import Casestudy from "./views/Casestudy";
import FundToFund from "./views/FundToFund";
import Nav from "./components/Nav";
// Import css files
import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import "./styles/index.css";
import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { MenuIcon } from "./assets/icons/";

function SimpleSlider() {
  const [menuState, setMenuState] = useState(true);
  var settings = {
    customPaging: function (i) {
      return (
        <a id={`section-${i}`} href="#0">
          {Nav(i)}
        </a>
      );
    },
    dots: true,
    infinite: true,
    draggable: true,
    fade: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: () => {
      let el = document.querySelector(".slick-dots");
      if (el.style.display === "flex" || el.style.display === "block") {
        toggleMenu();
        setMenuState(!menuState);
      }
    },
  };
  useEffect(() => {
    let el = document.querySelector(".slick-dots");
    el.style.display = "none";
  }, []);
  const toggleMenu = () => {
    window.scrollTo(0, 0);
    let el = document.querySelector(".slick-dots");
    // console.log(el.style.display);
    el.style.display === "flex"
      ? (el.style.display = "none")
      : (el.style.display = "flex");
  };

  return (
    <div>
      <a className="toggle-btn" href="#0" onClick={() => toggleMenu()}>
        <MenuIcon color={menuState ? "#FFF" : "#FFF"} />
      </a>
      <Slider {...settings}>
        <About />
        <DigitalCapsule toggleMenu={toggleMenu} menuState={menuState} />
        <InvestmentVehicle />
        <OpportunityZone />
        <Art />
        <Software />
        <FundToFund />
        <Casestudy />
      </Slider>
    </div>
  );
}

function App() {
  return (
    <section>
      <SimpleSlider />
    </section>
  );
}

export default App;
