import gbVentures from "../styles/section__gbVentures/ventures.module.scss";
import opportunityZone from "../assets/images/opportunity-zone.jpg";
import artOpportunity from "../assets/images/art-cooperative.jpg";
import platformCooperative from "../assets/images/platform-cooperative.jpg";
import partnerSponsor from "../assets/images/partner-sponsor.jpg";
import { ChevronBottom } from "../assets/icons";

const InvestmentVehicle = () => {
  const goto = (section) => {
    document.getElementById(section).click();
  };

  return (
    <article id="gb-ventures-section" className={gbVentures.section}>
      <div className="container">
        <div className="row">
          <div className="col-md-10">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <h4>GREEN BOOK</h4>
                  <h2 className={gbVentures.title}>INVESTMENT VEHICLES</h2>
                  <h5>
                    We’ve designed investment vehicles to provide all the
                    opportunity co-create wealth.
                  </h5>
                  <p className={gbVentures.subtitle}>
                    Our portfolio of investment vehicles are intended to
                    accomplish certain measured goals and diversity members
                    asset portfolio.
                  </p>
                  {/* <a
                    href="#0"
                    className={`btn btn-outline-light rounded-pill ${gbVentures.button}`}
                  >
                    Download Whitepaper
                  </a> */}
                </div>
                <div className="container">
                  <div className={`row ${gbVentures.carousel}`}>
                    <div
                      className={`col-md-3 no-space ${gbVentures.thumbWrapper}`}
                    >
                      <a
                        href="#0"
                        onClick={() => goto("section-3")}
                        className={`d-flex align-items-center justify-content-center ${gbVentures.thumb}`}
                      >
                        <img src={opportunityZone} alt="" />
                      </a>
                      <h5
                        className={`d-flex align-items-center justify-content-center ${gbVentures.thumbTitle}`}
                      >
                        Real Estate
                      </h5>
                      <ChevronBottom />
                    </div>
                    <div
                      className={`col-md-3 no-space ${gbVentures.thumbWrapper}`}
                    >
                      <a
                        href="#0"
                        onClick={() => goto("section-4")}
                        className={`d-flex align-items-center justify-content-center ${gbVentures.thumb}`}
                      >
                        <img src={artOpportunity} alt="" />
                      </a>
                      <h5
                        className={`d-flex align-items-center justify-content-center ${gbVentures.thumbTitle}`}
                      >
                        Art
                      </h5>
                      <ChevronBottom />
                    </div>
                    <div
                      className={`col-md-3 no-space ${gbVentures.thumbWrapper}`}
                    >
                      <a
                        href="#0"
                        onClick={() => goto("section-5")}
                        className={`d-flex align-items-center justify-content-center ${gbVentures.thumb}`}
                      >
                        <img src={platformCooperative} alt="" />
                      </a>
                      <h5
                        className={`d-flex align-items-center justify-content-center ${gbVentures.thumbTitle}`}
                      >
                        Community Shares
                      </h5>
                      <ChevronBottom />
                    </div>
                    <div
                      className={`col-md-3 no-space ${gbVentures.thumbWrapper}`}
                    >
                      <a
                        href="#0"
                        onClick={() => goto("section-7")}
                        className={`d-flex align-items-center justify-content-center ${gbVentures.thumb}`}
                      >
                        <img src={partnerSponsor} alt="" />
                      </a>
                      <h5
                        className={`d-flex align-items-center justify-content-center ${gbVentures.thumbTitle}`}
                      >
                        Fund-to-Funds
                      </h5>
                      <ChevronBottom />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </article>
  );
};

export default InvestmentVehicle;
