import opportunityZoneStyles from "../styles/section__opportunity__zone/opportunityZone.module.scss";
const OpportunityZone = () => (
  <article
    id="opportunity-zone-section"
    className={opportunityZoneStyles.section}
  >
    <div className="container">
      <div className="row">
        <div className="col-md-10">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2 className={opportunityZoneStyles.title}>
                  Opportunity Zone <br />
                  Real Estate <br />
                  Investment Fund
                </h2>
                {/* <h5 className={opportunityZoneStyles.subtitle}>
                  REAL ESTATE INVESTMENT COOPERATIVE & FUND
                </h5> */}
                <p>
                  Green Book opportunity zone investment fund empowers
                  communities and invests in the future of the people who live
                  in them. Green Book OZ Fund, is designed to make a path
                  forward to co-create wealth, and increase the value fo
                  communities assets and collective labor. The company's mission
                  is empowering underserved populations while also rewarding
                  investors with sustainable financial returns- all without
                  compromising values or integrity.
                </p>
                <a
                  href="#0"
                  className={`btn btn-light rounded-pill ${opportunityZoneStyles.button}`}
                >
                  Download Whitepaper
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </article>
);

export default OpportunityZone;
