import caseStudy from "../styles/section__casestudy/casestudy.module.scss";
const Casestudy = () => (
  <article id="opportunity-zone-section" className={caseStudy.section}>
    <div className={caseStudy.container}>
      <div>
        <h2 className={caseStudy.title}>Case study</h2>
        <p>
          If we can impact the most challenged city in America, it can serve as
          a blueprint for other cities around the nation. The city of Cleveland
          statistically has the largest wealth gap in America. For this reason,
          we have chosen Cleveland as our pilot city for the Green Book
          Opportunity Zone Fund. Learn our plans to invest in real estate, art,
          and businesses to uplift, beautify and increase the value of America's
          most distressed zip codes.
        </p>
        <a
          href="#0"
          className={`btn btn-light rounded-pill ${caseStudy.button}`}
        >
          Download Whitepaper
        </a>
      </div>
    </div>
  </article>
);

export default Casestudy;
